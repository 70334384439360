.pc-property {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px auto;
  width: 600px;

  > .price {
    font-size: 20px;
    font-weight: bold;
  }

  > .description {
    font-size: 12px;
  }

  > .actions {
    display: flex;
    justify-content: space-evenly;
  }
}
