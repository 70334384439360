.pc-display-token {
  background-color: var(--dark-blue);
  color: var(--white);
  display: flex;
  justify-content: center;
  padding: 5px;
  svg {
    cursor: pointer;
  }
}
