.pc-content {
  margin: 0 auto;
  max-width: 1248px;
  padding: 12px 0;

  &.sidebar {
    display: flex;

    > .ant-menu {
      width: 200px;
    }

    > .content {
      flex-grow: 1;
    }
  }

  h5.ant-typography {
    margin: 12px 0 12px 12px;
  }

  .ant-table-thead > tr > th.ant-table-cell {
    background-color: var(--white);
    padding: 4px 12px 8px;
  }
}
