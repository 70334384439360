.pc-module-selector {
    display: flex;
    justify-content: space-between;
    margin: 100px auto;
    width: 500px;

    > a {
        background-color: var(--dark-blue);
        color: var(--white);
        font-family: var(--sans-serif);
        padding: 20px 0;
        text-align: center;
        text-decoration: none;
        width: 200px;

        &:hover {
            font-weight: bold;
        }
    }
}
