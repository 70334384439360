.pc-button {
  background-color: var(--blue);
  border: none;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  padding: 6px 16px;

  &:hover {
    background-color: var(--dark-blue);
  }
}
