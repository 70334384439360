.pc-properties {
  display: flex;
  gap: 12px;

  > .property {
    border: 1px solid var(--light-gray);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    gap: 8px;
    padding: 5px;
    width: 300px;

    > .picture > img {
      width: 290px;
    }

    > .price {
      font-size: 20px;
      font-weight: bold;
    }

    > .description {
      font-size: 12px;
    }

    > .actions {
      display: flex;
      justify-content: space-evenly;
    }
  }
}
