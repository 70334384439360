.pc-consumer-switcher {
  margin: 100px auto;
  width: 200px;

  > .pc-space-between {
    margin-bottom: 20px;
  }

  > a {
    background-color: var(--light-blue);
    border-radius: 6px;
    color: var(--white);
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 6px 16px;
    text-align: center;
    text-decoration: none;
  }
}
