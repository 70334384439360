:root {
    --sans-serif: Inter, -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
    --monospace: "Roboto Mono", SFMono, consolas, monaco, monospace;
    --font-size-24: 1.5rem;
    --font-size-20: 1.25rem;
    --font-size-18: 1.125rem;
    --font-size-17: 1.0625rem;
    --font-size-16: 1rem;
    --font-size-14: 0.875rem;
    --font-size-12: 0.75rem;
    --font-size-11: 11px;
    --font-size-10: 0.625rem;
    --line-height-solid: 1;
    --line-height-32: 2rem;
    --line-height-28: 1.75rem;
    --line-height-24: 1.5rem;
    --line-height-20: 1.25rem;
    --line-height-18: 1.125rem;
    --line-height-16: 1rem;
    --line-height-14: 0.875rem;
    --line-height-12: 0.75rem;
    --nav-bar-height: 48px;
    --white: #fff;
    --black: #000;
    --near-black: #0d0d0d;
    --dark-gray: #222;
    --medium-gray: #555;
    --gray: #777;
    --light-gray: #999;
    --lightest-gray: #b3b3b3;
    --lighter-gray: #c7c7c7;
    --washed-gray: #dfdfdf;
    --faint-gray: #ebebeb;
    --near-white: #f6f6f6;
    --fainter-gray: #f9f9f9;
    --dark-blue: #0b7ada;
    --blue: #2494f4;
    --light-blue: #4ca6f6;
    --lightest-blue: #81c2f8;
    --washed-blue: #d8ecfd;
    --faint-blue: #ecf6fe;
    --fainter-blue: #f5faff;
    --dark-red: #c32230;
    --red: #d7444c;
    --light-red: #ea6c76;
    --lightest-red: #f9aaaf;
    --washed-red: #fadbde;
    --faint-red: #fdedee;
    --fainter-red: #fef6f7;
    --dark-green: #2a7e49;
    --green: #349d5b;
    --light-green: #3fc06f;
    --lightest-green: #71d094;
    --washed-green: #baefcd;
    --faint-green: #e0f5e8;
    --fainter-green: #f7fdf9;
    --dark-yellow: #c88912;
    --yellow: #f0b030;
    --light-yellow: #f1c160;
    --lightest-yellow: #fed582;
    --washed-yellow: #fde8be;
    --faint-yellow: #fef5e1;
    --fainter-yellow: #fffcf5;
    --dark-plum: #9846a4;
    --plum: #b062bc;
    --light-plum: #cf91d8;
    --lightest-plum: #e2bee4;
    --washed-plum: #f7def7;
    --faint-plum: #fceffc;
    --fainter-plum: #fff8ff;
    --dark-orange: #e4782f;
    --orange: #f28d49;
    --light-orange: #f5a570;
    --lightest-orange: #f9bf98;
    --washed-orange: #fce1cf;
    --faint-orange: #fef3ec;
    --fainter-orange: #fef9f6;
    --purple: #8474e4;
    --lightest-purple: #f1f2ff;
    --fainter-purple: #f2eefa;
    --darkest-purple: #2a3766;
    --dark-purple: #525fbe;
    --indigo: #6976d9;
    --default-ring: 0 0 0 1px var(--washed-gray);
    --hover-ring: 0 0 0 1px var(--lightest-blue);
    --focus-ring: 0 0 0 1px var(--lightest-blue);
    --error-ring: 0 0 0 1px var(--lightest-red);
    --error-default-ring: 0 0 0 1px var(--lightest-red);
    --inset-default-ring: inset 0 0 0 1px var(--washed-gray);
    --inset-hover-ring: inset 0 0 0 1px var(--lighter-gray);
    --inset-focus-ring: inset 0 0 0 1px var(--blue);
    --inset-error-ring: inset 0 0 0 1px var(--light-red);
    --inset-error-default-ring: inset 0 0 0 1px var(--lightest-red);
    --help-bubble-z-index: 2147483003;
    --tutorial-z-index: calc(var(--help-bubble-z-index) + 1);
    --hover-browser-z-index: calc(var(--help-bubble-z-index) + 1);
    --omnibox-z-index: 1010;
    --floating-presentation-nav-z-index: 800;
    --modal-click-outside-z-index: 700;
    --editor-draggable-modal-z-index: 900;
    --onboarding-hints-z-index: 10000;
    --onboarding-welcome-screen: 2147483004;
    --context-menu-z-index: 9999;
    --icon-size: 1.1666666667em
}

@supports (font-variation-settings: normal) {
    :root {
        --sans-serif: "Inter var", Inter, -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif
    }
}

:root {
    --sans-serif: Inter, -apple-system, BlinkMacSystemFont, system-ui, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
    --monospace: "Roboto Mono", SFMono, consolas, monaco, monospace;
    --font-size-24: 1.5rem;
    --font-size-20: 1.25rem;
    --font-size-18: 1.125rem;
    --font-size-17: 1.0625rem;
    --font-size-16: 1rem;
    --font-size-14: 0.875rem;
    --font-size-12: 0.75rem;
    --font-size-11: 11px;
    --font-size-10: 0.625rem;
    --line-height-solid: 1;
    --line-height-32: 2rem;
    --line-height-28: 1.75rem;
    --line-height-24: 1.5rem;
    --line-height-20: 1.25rem;
    --line-height-18: 1.125rem;
    --line-height-16: 1rem;
    --line-height-14: 0.875rem;
    --line-height-12: 0.75rem;
    --nav-bar-height: 48px;
    --white: #fff;
    --black: #000;
    --near-black: #0d0d0d;
    --dark-gray: #222;
    --medium-gray: #555;
    --gray: #777;
    --light-gray: #999;
    --lightest-gray: #b3b3b3;
    --lighter-gray: #c7c7c7;
    --washed-gray: #dfdfdf;
    --faint-gray: #ebebeb;
    --near-white: #f6f6f6;
    --fainter-gray: #f9f9f9;
    --dark-blue: #0b7ada;
    --blue: #2494f4;
    --light-blue: #4ca6f6;
    --lightest-blue: #81c2f8;
    --washed-blue: #d8ecfd;
    --faint-blue: #ecf6fe;
    --fainter-blue: #f5faff;
    --dark-red: #c32230;
    --red: #d7444c;
    --light-red: #ea6c76;
    --lightest-red: #f9aaaf;
    --washed-red: #fadbde;
    --faint-red: #fdedee;
    --fainter-red: #fef6f7;
    --dark-green: #2a7e49;
    --green: #349d5b;
    --light-green: #3fc06f;
    --lightest-green: #71d094;
    --washed-green: #baefcd;
    --faint-green: #e0f5e8;
    --fainter-green: #f7fdf9;
    --dark-yellow: #c88912;
    --yellow: #f0b030;
    --light-yellow: #f1c160;
    --lightest-yellow: #fed582;
    --washed-yellow: #fde8be;
    --faint-yellow: #fef5e1;
    --fainter-yellow: #fffcf5;
    --dark-plum: #9846a4;
    --plum: #b062bc;
    --light-plum: #cf91d8;
    --lightest-plum: #e2bee4;
    --washed-plum: #f7def7;
    --faint-plum: #fceffc;
    --fainter-plum: #fff8ff;
    --dark-orange: #e4782f;
    --orange: #f28d49;
    --light-orange: #f5a570;
    --lightest-orange: #f9bf98;
    --washed-orange: #fce1cf;
    --faint-orange: #fef3ec;
    --fainter-orange: #fef9f6;
    --purple: #8474e4;
    --lightest-purple: #f1f2ff;
    --fainter-purple: #f2eefa;
    --darkest-purple: #2a3766;
    --dark-purple: #525fbe;
    --indigo: #6976d9;
    --default-ring: 0 0 0 1px var(--washed-gray);
    --hover-ring: 0 0 0 1px var(--lightest-blue);
    --focus-ring: 0 0 0 1px var(--lightest-blue);
    --error-ring: 0 0 0 1px var(--lightest-red);
    --error-default-ring: 0 0 0 1px var(--lightest-red);
    --inset-default-ring: inset 0 0 0 1px var(--washed-gray);
    --inset-hover-ring: inset 0 0 0 1px var(--lighter-gray);
    --inset-focus-ring: inset 0 0 0 1px var(--blue);
    --inset-error-ring: inset 0 0 0 1px var(--light-red);
    --inset-error-default-ring: inset 0 0 0 1px var(--lightest-red);
    --help-bubble-z-index: 2147483003;
    --tutorial-z-index: calc(var(--help-bubble-z-index) + 1);
    --hover-browser-z-index: calc(var(--help-bubble-z-index) + 1);
    --omnibox-z-index: 1010;
    --floating-presentation-nav-z-index: 800;
    --modal-click-outside-z-index: 700;
    --editor-draggable-modal-z-index: 900;
    --onboarding-hints-z-index: 10000;
    --onboarding-welcome-screen: 2147483004;
    --context-menu-z-index: 9999;
    --icon-size: 1.1666666667em
}

/* Ant override */
.ant-descriptions-view {
    padding-left: 12px;
}

.ant-descriptions .ant-descriptions-row >th.ant-descriptions-item {
    padding-bottom: 0;
}
